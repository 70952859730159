import actions from "modules/actions/physicians-actions";
import payorAccount from "store/actions/payor-account";
import { uuid } from "vue-uuid";

export default {
  name: "Physician",
  props: {
    data: Object,
  },
  data() {
    return {
      actions: new actions(),
      payorAccount: new payorAccount(),
      loaderMesg: "Loading physician data...",
      clientModal: false,
      confirmationModal: false,
      isAtPageBottom: false,
      licenseStatuses: [],
      defaultClient: null,
      physiciansDefaultClientId: null,
      panel: [0],
      phoneRefresher: 0,
      physician: {
        defaultEmail: {
          id: null,
        },
        defaultPhone: {
          id: null,
        },
        emails: [
          {
            id: null,
            uuid: uuid.v1(),
            email: "",
            emailTypeId: "",
            isDefault: true,
          },
        ],
        phones: [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            phone: "",
            areaCode: "",
          },
        ],
        effectivityDate: "",
        firstName: "",
        id: null,
        lastName: "",
        licenseState: "",
        licenseStatus: "",
        middleName: "",
        npi: { id: "", npiNumber: "" },
        physicianId: "",
        taxonomy: "",
        stateMedicalLicense: {
          id: null,
          licenseNumber: "",
        },
        terminationDate: "",
        payorAccounts: [],
        defaultAddress: {
          line1: "",
          line2: "",
          city: "",
          stateProvince: "",
          postalCode: "",
          country: "",
        },
      },
      physicianData: null,
      menu1: false,
      menu2: false,
      date1: "",
      date2: "",
      isLoading: true,
      isFieldLoading: {
        npi: false,
        licenseNumber: false,
      },
      physicianModalVisibility: false,
      emailTypes: [],
      defaultEmailIndex: 0,
      rules: {
        required: (val) => {
          if (val !== null && typeof val === "object")
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateNpi: async (val, uuid) =>
          (await this.doValidateNpiNumber(val, uuid)) ||
          "This field is required.",
        validateLicenseNumber: async (val) =>
          (await this.doValidateLicenseNumber(val)) ||
          "This field is required.",
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
        validTerminationDate: (val, effectivityDate) => {
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          if (effectivityDate === null || !effectivityDate.length) return true;

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  computed: {
    changesOccur() {
      const data = this.physician;
      const dataKeys = Object.keys(data);

      for (let i = 0; i < dataKeys.length; i++) {
        if (this.isAdd) {
          if (typeof data[dataKeys[i]] === "object" && data[dataKeys[i]]) {
            if (this.isDataChange(data[dataKeys[i]])) return true;
          } else if (data[dataKeys[i]]) return true;
        } else {
          if (!this.physicianData) return false;
          const dataCopy = this.physicianData;
          if (typeof data[dataKeys[i]] === "object" && data[dataKeys[i]]) {
            if (this.isDataChange(data[dataKeys[i]], dataCopy[dataKeys[i]])) {
              return true;
            }
          } else if (data[dataKeys[i]] !== dataCopy[dataKeys[i]]) return true;
        }
      }
      return false;
    },
    disableDeleteEmail() {
      return this.physician.emails.length < 2;
    },
    isAdd() {
      return this.$route.name == "add-physician";
    },
    submitAction() {
      return this.isAdd ? this.savePhysician : this.updatePhysician;
    },
    __LicenseNumber: {
      get() {
        return this.physician.stateMedicalLicense.licenseNumber.toUpperCase();
      },
      set(val) {
        this.physician.stateMedicalLicense.licenseNumber = val.toUpperCase();
      },
    },
  },
  methods: {
    async updatePhysician(evt) {
      this.isLoading = true;
      const validatePhysician = await evt.target.__vue__.validateAsync();
      const validatePhones =
        await this.$refs.physicianPhones.$refs.form.validateAsync();
      if (validatePhysician && validatePhones) {
        if (!this.physician.payorAccounts.length) {
          this.showNotifyMessage({
            message: "Add atleast one (1) client account.",
            type: "danger",
          });
          this.isLoading = false;
          return;
        }

        const {
          id,
          phones,
          emails,
          payorAccounts,
          npi,
          firstName,
          lastName,
          middleName,
          taxonomy,
          licenseState,
          licenseStatus,
          effectivityDate,
          terminationDate,
          stateMedicalLicense,
        } = this.physician;

        const physicianCopy = this.physicianData;

        let queries = {};
        let collectionQueries = [];

        this.loaderMesg = "Updating physician data...";
        try {
          if (JSON.stringify(phones) !== JSON.stringify(physicianCopy.phones)) {
            for (let i = 0; i < phones.length; i++) {
              if (!phones[i].id) {
                const result = await this.actions.createPhone({
                  phone: {
                    type: "String!",
                    value: this.sanitizePhoneString(phones[i].phone),
                    unincludeToFields: true,
                  },
                  areaCode: {
                    type: "String",
                    value: this.sanitizePhoneString(phones[i].areaCode),
                    unincludeToFields: true,
                  },
                });

                collectionQueries.push({
                  addPhone: {
                    phoneId: {
                      value: result.id,
                      type: "UUID!",
                    },
                  },
                });
                phones[i].id = result.id;
              } else if (
                JSON.stringify(phones[i]) !==
                JSON.stringify(
                  physicianCopy.phones.find((phone) => phone.id == phones[i].id)
                )
              )
                collectionQueries.push({
                  updatePhone: {
                    phoneId: {
                      value: phones[i].id,
                      type: "UUID!",
                    },
                    phone: {
                      value: this.sanitizePhoneString(phones[i].phone),
                      type: "String",
                    },
                    areaCode: {
                      type: "String",
                      value: this.sanitizePhoneString(phones[i].areaCode),
                      unincludeToFields: true,
                    },
                  },
                });
            }
            const toRemove = this.physicianData.phones
              .filter(
                (phone) =>
                  !phones.map((phoneData) => phoneData.id).includes(phone.id)
              )
              .map((phone) => phone.id);

            for (let i = 0; i < toRemove.length; i++) {
              if (toRemove[i]) {
                collectionQueries.push({
                  deletePhone: {
                    phoneId: {
                      value: toRemove[i],
                      type: "UUID!",
                    },
                  },
                });
              }
            }
          }

          if (JSON.stringify(emails) !== JSON.stringify(physicianCopy.emails)) {
            for (let i = 0; i < emails.length; i++) {
              if (!emails[i].id) {
                const result = await this.actions.createEmail({
                  variables: {
                    email: {
                      type: "String!",
                      value: emails[i].email,
                    },
                  },
                  queries: {
                    setEmailType: {
                      emailTypeId: {
                        type: "UUID!",
                        value: emails[i].emailTypeId,
                      },
                    },
                  },
                });

                collectionQueries.push({
                  addEmail: {
                    emailId: {
                      value: result.id,
                      type: "UUID!",
                    },
                  },
                });

                emails[i].id = result.id;
              } else if (
                JSON.stringify(emails[i]) !==
                JSON.stringify(
                  physicianCopy.emails.find((email) => email.id == emails[i].id)
                )
              )
                collectionQueries.push({
                  updateEmail: {
                    emailId: {
                      value: emails[i].id,
                      type: "UUID!",
                    },
                    email: {
                      value: emails[i].email,
                      type: "String",
                    },
                    emailTypeId: {
                      value: emails[i].emailTypeId,
                      type: "UUID!",
                    },
                  },
                });

              if (emails[i].isDefault) {
                queries["setDefaultEmail"] = {
                  emailId: {
                    type: "UUID!",
                    value: emails[i].id,
                  },
                };
              }
            }
          }

          const findDefaultPhone = phones.find((phone) => phone.isDefault);

          if (findDefaultPhone) {
            queries["setDefaultPhone"] = {
              phoneId: {
                value: findDefaultPhone.id,
                type: "UUID!",
              },
            };
          } else
            queries["setDefaultPhone"] = {
              phoneId: {
                value: phones[0].id,
                type: "UUID!",
              },
            };

          if (npi.npiNumber !== physicianCopy.npi.npiNumber) {
            queries["updateNpi"] = {
              npiNumber: {
                type: "String",
                value: npi.npiNumber,
              },
            };
          }

          queries["updateStateMedicalLicense"] = {
            licenseNumber: {
              type: "String",
              value: stateMedicalLicense.licenseNumber,
            },
          };

          if (
            JSON.stringify(payorAccounts) !==
            JSON.stringify(physicianCopy.payorAccounts)
          ) {
            const existingClient = [...physicianCopy.payorAccounts];
            for (let i = 0; i < payorAccounts.length; i++) {
              let index = existingClient.findIndex(
                (existing) => existing.id == payorAccounts[i].id
              );
              if (index !== -1) existingClient.splice(index, 1);
              else
                collectionQueries.push({
                  addPayorAccount: {
                    payorAccountId: {
                      value: payorAccounts[i].id,
                      type: "UUID!",
                    },
                  },
                });
            }

            if (existingClient.length)
              for (let i = 0; i < existingClient.length; i++)
                collectionQueries.push({
                  deletePayorAccount: {
                    payorAccountId: {
                      type: "UUID!",
                      value: existingClient[i].id,
                    },
                  },
                });
          }

          if (this.defaultClient !== this.physiciansDefaultClientId)
            queries["setDefaultPayorAccount"] = {
              payorAccountId: {
                value: this.defaultClient,
                type: "UUID!",
              },
            };

          if (middleName !== physicianCopy.middleName)
            queries["updateName"] = {
              firstName: {
                type: "String",
                value: firstName,
              },
              lastName: {
                type: "String",
                value: lastName,
              },
              middleName: {
                type: "String",
                value: middleName,
              },
            };
          else
            queries["updateName"] = {
              firstName: {
                type: "String",
                value: firstName,
              },
              lastName: {
                type: "String",
                value: lastName,
              },
            };

          if (taxonomy !== physicianCopy.taxonomy)
            queries["updateTaxonomy"] = {
              taxonomy: {
                type: "String",
                value: taxonomy,
              },
            };

          if (licenseState !== physicianCopy.licenseState)
            queries["updateLicenseState"] = {
              licenseState: {
                type: "String",
                value: licenseState,
              },
            };

          if (licenseState !== physicianCopy.licenseState)
            queries["updateLicenseState"] = {
              licenseState: {
                type: "String",
                value: licenseState,
              },
            };

          if (licenseStatus !== physicianCopy.licenseStatus)
            queries["updateLicenseStatus"] = {
              licenseStatus: {
                type: "LicenseStatus",
                value: licenseStatus,
              },
            };

          if (effectivityDate !== physicianCopy.effectivityDate || terminationDate !== physicianCopy.terminationDate) {
            collectionQueries.push({
              updateValidityDates: {
                effectivityDate: {
                  type: "String",
                  value: effectivityDate
                    ? this.$options.filters.getTimeDate(
                        effectivityDate,
                        "YYYY-MM-DD HH:mm:ss",
                        false,
                        null,
                        this.getTimezoneOffset({
                          date: effectivityDate,
                        }),
                      ).utc
                    : "",
                  unincludeToFields: true,
                },
                terminationDate: {
                  type: "String",
                  value: terminationDate
                    ? this.$options.filters.getTimeDate(
                        terminationDate,
                        "YYYY-MM-DD HH:mm:ss",
                        false,
                        null,
                        {
                          days: 1,
                          ...this.getTimezoneOffset({
                            date: terminationDate,
                            minusSeconds: 1,
                          }),
                        }
                      ).utc
                    : "",
                  unincludeToFields: true,
                },
                mutationReturn: ["success", "errors"],
              }
            });
          }

          await this.actions.updatePhysician({
            queries,
            collectionQueries,
            params: {
              physicianId: {
                type: "UUID!",
                value: id,
              },
            },
          });

          this.showNotifyMessage({
            message: "Physician updated successfuly.",
            type: "success",
          });
          this.$router.push("/system-maintenance/clients-physicians/physician");
          this.isLoading = false;
        } catch (err) {
          this.showNotifyMessage({
            message: "Problem has occurred while saving data.",
            type: "danger",
          });
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.showNotifyMessage({
          message: "Please fill all the required fields.",
          type: "danger",
        });
      }
    },
    async getLicenseStatuses() {
      try {
        if (!this.licenseStatuses.length) {
          this.licenseStatuses = [
            { text: "Fetching license statues...", disabled: true },
          ];

          const result = await this.actions.getEnumValues("LicenseStatus");

          if (result) {
            this.licenseStatuses = result.enumValues.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async doValidateNpiNumber(npi) {
      try {
        this.isFieldLoading.npi = true;

        if (!/^\d{10}$/.test(npi)) {
          return "NPI number must have a min/max field length of 10.";
        }

        const validateNpiExternally = await this.validateNpiNumber(npi);
        if (typeof validateNpiExternally === "string") {
          return validateNpiExternally;
        } else {
          const result = await this.actions.searchNpi(npi);
          if (result.length) {
            return (
              result[0].id === this.physician.npi.id ||
              "NPI number already exists."
            );
          } else return true;
        }
      } catch (err) {
        if (npi.charAt(0) == 0) return "Invalid NPI number.";
        return "An error occur, cannot retrieve data from NPI Files Data.";
      } finally {
        this.isFieldLoading.npi = false;
      }
    },
    async doValidateLicenseNumber(licenseNumber) {
      try {
        this.isFieldLoading.licenseNumber = true;

        if (!licenseNumber.length) return "This field is required.";

        const result = await this.actions.getPhysicians({
          filter: {
            stateMedicalLicense: licenseNumber,
            enum_patternMatch: "EXACT",
          },
          limitData: ["physicianId"],
        });

        if (result.length && result[0].id !== this.physician.id)
          return "License number already exist.";

        return true;
      } catch (err) {
        return "An error occur, cannot retrieve data from License numbers.";
        // throw Error(err);
      } finally {
        this.isFieldLoading.licenseNumber = false;
      }
    },
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.emailTypes = [];
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    parseDate(date) {
      if (!date || !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(dataModel, menu, date) {
      this.physician[dataModel] = this.formatDate(date);
      this[menu] = false;
    },
    async savePhysician(evt) {
      this.isLoading = true;
      const validatePhysician = await evt.target.__vue__.validateAsync();
      const validatePhones =
        await this.$refs.physicianPhones.$refs.form.validateAsync();
      const validateEmail =
        await this.$refs.physicianEmails.$refs.form.validateAsync();

      if (validatePhysician && validatePhones && validateEmail) {
        if (!this.physician.payorAccounts.length) {
          this.showNotifyMessage({
            message: "Add atleast one (1) client account.",
            type: "danger",
          });
          this.isLoading = false;
          return;
        }

        const {
          phones,
          emails,
          payorAccounts,
          npi,
          firstName,
          lastName,
          middleName,
          taxonomy,
          licenseState,
          licenseStatus,
          effectivityDate,
          terminationDate,
          stateMedicalLicense,
        } = this.physician;

        let queries = {};
        let collectionQueries = [];

        this.loaderMesg = "Saving physician data...";
        try {
          for (let i = 0; i < phones.length; i++) {
            if (!phones[i].id) {
              const result = await this.actions.createPhone({
                phone: {
                  type: "String!",
                  value: this.sanitizePhoneString(phones[i].phone),
                  unincludeToFields: true,
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(phones[i].areaCode),
                  unincludeToFields: true,
                },
              });

              phones[i].id = result.id;
            }

            collectionQueries.push({
              addPhone: {
                phoneId: {
                  value: phones[i].id,
                  type: "UUID!",
                },
              },
            });

            if (phones[i].isDefault)
              queries["setDefaultPhone"] = {
                phoneId: {
                  value: phones[i].id,
                  type: "UUID!",
                },
              };
          }

          for (let i = 0; i < emails.length; i++) {
            if (!emails[i].id) {
              const result = await this.actions.createEmail({
                variables: {
                  email: {
                    type: "String!",
                    value: emails[i].email,
                  },
                },
                queries: {
                  setEmailType: {
                    emailTypeId: {
                      type: "UUID!",
                      value: emails[i].emailTypeId,
                    },
                  },
                },
              });

              emails[i].id = result.id;
            }

            collectionQueries.push({
              addEmail: {
                emailId: {
                  value: emails[i].id,
                  type: "UUID!",
                },
              },
            });

            if (emails[i].isDefault)
              queries["setDefaultEmail"] = {
                emailId: {
                  value: emails[i].id,
                  type: "UUID!",
                },
              };
          }

          if (!npi.id) {
            const createNpi = await this.actions.createNpi({
              npiNumber: {
                value: npi.npiNumber,
                type: "String!",
              },
            });

            npi.id = createNpi.id;
          }

          if (!stateMedicalLicense.id) {
            const createLicenseNumber =
              await this.actions.createStateMedicalLicense({
                licenseNumber: {
                  value: stateMedicalLicense.licenseNumber,
                  type: "String!",
                },
              });

            stateMedicalLicense.id = createLicenseNumber.id;
          }

          for (let i = 0; i < payorAccounts.length; i++)
            collectionQueries.push({
              addPayorAccount: {
                payorAccountId: {
                  value: payorAccounts[i].id,
                  type: "UUID!",
                },
              },
            });

          if (this.defaultClient && this.defaultClient.length)
            queries["setDefaultPayorAccount"] = {
              payorAccountId: {
                value: this.defaultClient,
                type: "UUID!",
              },
            };

          if (licenseStatus.length)
            queries["updateLicenseStatus"] = {
              licenseStatus: {
                type: "LicenseStatus",
                value: licenseStatus,
              },
            };

          collectionQueries.push({
            updateValidityDates: {
              effectivityDate: {
                type: "String",
                value: effectivityDate
                  ? this.$options.filters.getTimeDate(
                      effectivityDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      this.getTimezoneOffset({
                        date: effectivityDate,
                      })
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              terminationDate: {
                type: "String",
                value: terminationDate
                  ? this.$options.filters.getTimeDate(
                      terminationDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      {
                        days: 1,
                        ...this.getTimezoneOffset({
                          date: terminationDate,
                          minusSeconds: 1,
                        }),
                      }
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            }
          });
          await this.actions.createPhysician({
            variables: {
              firstName: {
                type: "String!",
                value: firstName,
              },
              lastName: {
                type: "String!",
                value: lastName,
              },
              middleName: {
                type: "String",
                value: middleName,
              },
            },
            queries: {
              ...queries,
              setNpi: {
                npiId: {
                  type: "UUID!",
                  value: npi.id,
                },
              },
              updateTaxonomy: {
                taxonomy: {
                  type: "String",
                  value: taxonomy,
                },
              },
              updateLicenseState: {
                licenseState: {
                  type: "String",
                  value: licenseState,
                },
              },
              setStateMedicalLicense: {
                stateMedicalId: {
                  type: "UUID!",
                  value: stateMedicalLicense.id,
                },
              },
            },
            collectionQueries,
          });
          this.showNotifyMessage({
            message: "Physician added successfuly.",
            type: "success",
          });
          this.$router.push("/system-maintenance/clients-physicians/physician");
          this.isLoading = false;
        } catch (err) {
          this.showNotifyMessage({
            message: "Problem has occurred while saving data.",
            type: "danger",
          });
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.showNotifyMessage({
          message: "Please fill all the required fields.",
          type: "danger",
        });
      }
    },
    removeClient(index) {
      const client = this.physician.payorAccounts.splice(index, 1);
      if (this.defaultClient == client[0].id)
        this.defaultClient = this.physician.payorAccounts.length
          ? this.physician.payorAccounts[0].id
          : null;
    },
    populateSelected(clients) {
      this.physician.payorAccounts = clients;
      if (!clients.map((client) => client.id).includes(this.defaultClient))
        this.defaultClient = clients[0].id;
    },
    removePhone(id) {
      try {
        if (id)
          if (
            !this.actions.updatePhysician({
              queries: {
                deletePhone: {
                  phoneId: {
                    value: id,
                    type: "UUID!",
                  },
                },
              },
              params: {
                physicianId: {
                  value: this.physician.id,
                  type: "UUID!",
                },
              },
            })
          )
            throw "error";

        const index = this.physician.phones.findIndex(
          (phone) => phone.id == id
        );
        this.physician.phones.splice(index, 1);
      } catch (e) {
        this.showNotifyMessage({
          message: "Error occur while deleting phone, please try again.",
          type: "danger",
        });
      }
    },
    removeEmail(index, id) {
      try {
        if (id)
          this.$q
            .dialog({
              title: "Confirm",
              message:
                "Are you sure you want to remove this email from the current physician?",
              persistent: true,
              ok: "Yes",
              cancel: "No",
            })
            .onOk(async () => {
              const res = await this.actions.updatePhysician({
                queries: {
                  deleteEmail: {
                    emailId: {
                      value: id,
                      type: "UUID!",
                    },
                  },
                },
                params: {
                  physicianId: {
                    value: this.physician.id,
                    type: "UUID!",
                  },
                },
              });

              if (!res.deleteEmail)
                this.showNotifyMessage({
                  message: "Deleting email was unsuccessful, please try again.",
                  type: "danger",
                });

              this.physician.emails.splice(index, 1);
              if (this.defaultEmailIndex == index) this.defaultEmailIndex = 0;
            });
        else {
          this.physician.emails.splice(index, 1);
          if (this.defaultEmailIndex == index) this.defaultEmailIndex = 0;
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Error occur while deleting email, please try again.",
          type: "danger",
        });
      }
    },
    addEmail() {
      this.physician.emails.push({ id: null, email: "", emailTypeId: "" });
    },
    cancelChanges() {
      if (this.changesOccur) this.confirmationModal = true;
      else
        this.$router.push("/system-maintenance/clients-physicians/physician");
    },
    isDataChange(data, copyData = null) {
      let keys = Object.keys(data);
      if (this.isAdd) {
        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (this.isDataChange(data[i])) return true;
          }
        } else
          for (let i = 0; i < keys.length; i++) {
            if (["countryCode", "uuid", "uid"].includes(keys[i])) continue;
            else if (typeof data[keys[i]] === "object" && data[keys[i]]) {
              if (this.isDataChange(data[keys[i]])) return true;
            } else if (data[keys[i]] && data[keys[i]].length) return true;
          }
      } else {
        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (this.isDataChange(data[i], copyData[i])) return true;
          }
        } else
          for (let i = 0; i < keys.length; i++) {
            if (
              [
                "countryCode",
                "uuid",
                "uid",
                "defaultPhone",
                "defaultEmail",
              ].includes(keys[i])
            )
              continue;
            else if (typeof data[keys[i]] === "object" && data[keys[i]]) {
              if (this.isDataChange(data[keys[i]], copyData[keys[i]])) {
                return true;
              }
            } else if (keys[i] === "phone") {
              if (
                this.isDataChange(
                  this.sanitizePhoneString(data[keys[i]]),
                  copyData[keys[i]]
                )
              )
                return true;
            } else if (data[keys[i]] !== copyData[keys[i]]) {
              return true;
            }
          }
      }
      return false;
    },
    updatePhysicianData(data) {
      const {
        defaultEmail,
        defaultPhone,
        emails,
        phones,
        npi,
        stateMedicalLicense,
        terminationDate,
        effectivityDate,
        payorAccounts,
        defaultPayorAccount,
        licenseState,
        licenseStatus,
        taxonomy,
        defaultAddress,
      } = data;
      data.defaultEmail = defaultEmail
        ? {
            id: defaultEmail.id,
          }
        : null;

      data.defaultPhone = defaultPhone
        ? {
            id: defaultPhone.id,
          }
        : null;

      if (phones && phones.length) {
        data.phones = phones.map((phone, index) => {
          return {
            id: phone.id,
            uuid: uuid.v1(),
            isDefault: defaultPhone
              ? defaultPhone.id == phone.id
              : !index
              ? true
              : false,
            phone: this.sanitizePhoneString(phone.phone),
            areaCode: phone.areaCode ? `(${phone.areaCode})` : null,
          };
        });
      } else {
        data.phones = [
          {
            id: null,
            uuid: uuid.v1(),
            isDefault: true,
            phone: "",
            areaCode: "",
          },
        ];
      }

      if (emails && emails.length) {
        data.emails = emails.map((email) => {
          return {
            id: email.id,
            uuid: uuid.v1(),
            email: email.email,
            emailTypeId: email.emailType ? email.emailType.id : null,
            isDefault: defaultEmail ? email.id == defaultEmail.id : false,
          };
        });
      } else {
        data.emails = [
          { id: null, email: "", emailTypeId: "", isDefault: true },
        ];
      }

      if (payorAccounts && payorAccounts.length)
        data.payorAccounts = payorAccounts.map((client) => {
          delete client.__typename;
          return client;
        });

      if (defaultPayorAccount) {
        this.defaultClient = defaultPayorAccount.id;
        this.physiciansDefaultClientId = defaultPayorAccount.id;
      }

      if (npi) delete npi.__typename;
      else data.npi = this.physician.npi;

      if (stateMedicalLicense) delete stateMedicalLicense.__typename;
      else data.stateMedicalLicense = this.physician.stateMedicalLicense;

      if (effectivityDate) {
        this.date1 = this.$options.filters.getTimeDate(
          effectivityDate,
          "YYYY-MM-DD"
        ).zone;

        data.effectivityDate = this.$options.filters.getTimeDate(
          effectivityDate,
          "MM/DD/YYYY"
        ).zone;
      } else data.effectivityDate = "";

      if (terminationDate) {
        this.date2 = this.$options.filters.getTimeDate(
          terminationDate,
          "YYYY-MM-DD"
        ).zone;

        data.terminationDate = this.$options.filters.getTimeDate(
          terminationDate,
          "MM/DD/YYYY"
        ).zone;
      } else data.terminationDate = "";

      data.licenseState = licenseState || "";
      data.licenseStatus = licenseStatus || "";
      data.taxonomy = taxonomy || "";

      if (!defaultAddress || !defaultAddress.id) {
        data.defaultAddress = {
          line1: "n/a",
          line2: "n/a",
          city: "n/a",
          stateProvince: "n/a",
          postalCode: "n/a",
          country: "n/a",
        };
      }

      delete data.__typename;
      delete data.defaultPayorAccount;
      delete data.isDeleted;

      this.physician = data;
      this.physicianData = JSON.parse(JSON.stringify(data));
      this.$refs.form.resetValidation();
    },
    handleScroll(event) {
      const scrollableHeight =
        event.target.scrollingElement.offsetHeight - window.innerHeight;
      const footerOffset = 60;
      if (
        window.scrollY >= scrollableHeight - footerOffset &&
        !this.isAtPageBottom
      ) {
        this.isAtPageBottom = true;
      }
      if (
        window.scrollY < scrollableHeight - footerOffset &&
        this.isAtPageBottom
      ) {
        this.isAtPageBottom = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(async () => {
      try {
        if (!this.isAdd) {
          await this.getLicenseStatuses();
          const result = await this.actions.getPhysicianById({
            params: {
              id: this.$route.params.id,
            },
            limitData: [
              this.buildSubQuery("phones", ["phone", "areaCode"]),
              this.buildSubQuery("emails", [
                "email",
                this.buildSubQuery("emailType", ["label"]),
              ]),
              this.buildSubQuery("stateMedicalLicense", ["licenseNumber"]),
              this.buildSubQuery("defaultPhone", []),
              this.buildSubQuery("defaultEmail", []),
              this.buildSubQuery("defaultPayorAccount", []),
              this.buildSubQuery("payorAccounts", ["payorCode", "payorName"]),
              this.buildSubQuery("npi", ["npiNumber"]),
              this.buildSubQuery("defaultAddress", [
                "line1",
                "line2",
                "city",
                "stateProvince",
                "postalCode",
                "country",
              ]),
              "firstName",
              "lastName",
              "licenseState",
              "licenseStatus",
              "middleName",
              "effectivityDate",
              "terminationDate",
              "physicianId",
              "taxonomy",
            ],
          });
          this.updatePhysicianData(result);
          await this.getEmailTypes();
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
